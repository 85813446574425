<template>
  <div>
    <form-login-component />
  </div>
</template>

<script>
import FormLoginComponent from "@/components/auth/FormLoginComponent.vue";

export default {
  name: "FormLogin",
  title: "Autenticación | Baja California Health Tourism",
  components: {
    FormLoginComponent,
  },
};
</script>
